import request from '@/utils/request';
// 个人信息
export const getInfo = (data) => request({
  url: '/api/mycenter/index',
  method: 'POST',
  data
});
// 修改密码
export const modify_pass= (data) => request({
  url: '/api/mycenter/modify_pass',
  method: 'POST',
  data
});
//设置提现密码
export const cash_pass= (data) => request({
  url: '/api/mycenter/cash_pass',
  method: 'POST',
  data
});
// 消息中心
export const msgList = (data) => request({
  url: '/api/mycenter/message_center',
  method: 'POST',
  data
});
// 消息详情
export const msgDetail = (data) => request({
  url: '/api/mycenter/message_info',
  method: 'POST',
  data
});
// 消息已读
export const msgReal = (data) => request({
  url: '/api/mycenter/message_real',
  method: 'POST',
  data
});
// 我的课程
export const my_curriculum = (data) => request({
  url: '/api/mycenter/my_curriculum',
  method: 'POST',
  data
});
// 我的订单
export const my_order = (data) => request({
  url: '/api/mycenter/my_order',
  method: 'POST',
  data
});
// 推广收益
export const pushBalance = (data) => request({
  url: '/api/income/balance',
  method: 'POST',
  data
});
// 绑定学习卡
export const addCard = (data) => request({
  url: '/api/mycenter/add_card',
  method: 'POST',
  data
});
// 获取学习卡
export const getCard = (data) => request({
  url: '/api/mycenter/my_card',
  method: 'POST',
  data
});
// 点击签到
export const Sign = (data) => request({
  url: '/api/mycenter/points_log',
  method: 'POST',
  data
});
// 学习记录
export const studyHistory = (data) => request({
  url: '/api/mycenter/index_log',
  method: 'POST',
  data
});
// 个人信息修改
export const changeInfo = (data) => request({
  url: '/api/mycenter/user_edit',
  method: 'POST',
  data
});