<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.class_tabs {
  border-bottom: 1px solid #dddddd;
  .tabsList {
    width: 120px;
    text-align: center;
    font-size: 16px;
    color: #0c69e6;
    height: 64px;
    line-height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 4px solid #0c6ae7;
  }
}
.jilu_box {
  padding: 25px;
  box-sizing: border-box;
  .julu_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 168px;
    background: #0090f0;
    border-radius: 6px;
    padding: 20px 40px;
    box-sizing: border-box;
    margin-bottom: 60px;
    .jl_left {
      width: 420px;
      font-size: 14px;
      color: #fff;
      text-align: center;
      .span1 {
        margin-right: 20px;
      }
      b {
        font-size: 24px;
        margin: 0 10px;
      }
      .leiji_bd {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #fff;
        margin-top: 20px;
        > div {
          margin-top: 15px;
          > div {
            margin-bottom: 5px;
          }
        }
      }
    }
    .jl_right {
      width: 207px;
      height: 153px;
      margin-right: 60px;
      margin-bottom: -40px;
    }
  }
}
</style>
<template>
  <div>
    <div class="class_tabs">
      <div class="tabsList">学习记录</div>
    </div>
    <div class="jilu_box">
      <div class="julu_top">
        <div class="jl_left">
          <div class="leiji">
            <span class="span1">累计学习总时长</span>
            <b>{{ history.study_h }}</b> 时 <b>{{ history.study_i }}</b
            >分
          </div>
          <div class="leiji_bd">
            <div>
              <div>累计学习天数</div>
              <b>{{ history.study_day }}</b>
            </div>
            <div>
              <div>累计学习课程</div>
              <b>{{ history.cu_num }}</b
              >课时
            </div>
            <div>
              <div>累计做题数量</div>
              <b>{{ history.question_num }}</b
              >道
            </div>
          </div>
        </div>
        <img class="jl_right" src="../../assets/img/personal/jl.png" alt="" />
      </div>
      <!-- chart -->
      <div id="chartmain" style="width: 900px; height: 500px"></div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import { studyHistory } from "../../assets/js/personal";
export default {
  data() {
    return {
      option: {},
      history: {},
    };
  },
  mounted() {
    this.getHistory();
    this.init();
    // this.$nextTick(() => {});
    // setTimeout(() => {
    // }, 10);
  },
  methods: {
    // 初始化
    init() {
      //指定图标的配置和数据
      this.option = {
        title: {
          text: "最近7天学习情况",
          x: "center",
          textStyle: {
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        tooltip: {
          // triggrt:'axis',
          //   formatter : function(params){
          // 				console.log(params);//打印断点，会看到自己想要的后台数据
          // 				var res = 'success';  //可以在这个方法中做改变
          // 				return res;
          // 		}
        },
        //   legend: {
        //     type: 'scroll',
        //     orient: 'vertical',
        //     right: 10,
        //     top: 20,
        //     bottom: 20,
        //     data: ["1","2","3"],

        // },
        grid: {
          top: "20%", //距上边距
        },
        legend: {
          type: "plain",
          orient: "vertical",
          x: "right",
          data: [
            { name: "学习课时", textStyle: { color: "#F13232" } },
            // { name: "及格率", textStyle: { color: "#0090F0" } },
            { name: "做卷数", textStyle: { color: "#FF8A00" } },
          ],
          padding: [10, 125, 0, 0],
        },
        xAxis: {
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: "#F2F2F2",
              width: 1,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#F2F2F2"],
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#F2F2F2",
              width: 1,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#F2F2F2"],
              width: 1,
              type: "solid",
            },
          },
        },
        series: [
          {
            name: "学习课时",
            type: "line",
            data: [],
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: "#F13232",
              width: 1,
            },
            // 折线拐点的样式
            itemStyle: {
              normal: {
                // 静止时：
                color: "#F13232",
              },
            },
          },
          // {
          //   name: "及格率",
          //   type: "line",
          //   data: [100, 150, 300, 100, 300, 250, 400],
          //   smooth: true,
          //   symbolSize: 8,
          //   lineStyle: {
          //     color: "#0090F0",
          //     width: 1,
          //   },
          //   // 折线拐点的样式
          //   itemStyle: {
          //     normal: {
          //       // 静止时：
          //       color: "#0090F0",
          //     },
          //   },
          // },
          {
            name: "做卷数",
            type: "line",
            data: [],
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: "#FF8A00",
              width: 1,
            },
            // 折线拐点的样式
            itemStyle: {
              normal: {
                // 静止时：
                color: "#FF8A00",
              },
            },
          },
        ],
      };
      //初始化echarts实例
      var myChart = echarts.init(document.getElementById("chartmain"));
      //使用制定的配置项和数据显示图表
      myChart.setOption(this.option);
    },
    getHistory() {
      let that = this;
      studyHistory({
        id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.history = res.data;
          this.option.xAxis.data = res.data.detail.x;
          this.option.series[0].data = res.data.detail.study_num;
          this.option.series[1].data = res.data.detail.que_num;
          var myChart = echarts.init(document.getElementById("chartmain"));
          myChart.setOption(this.option);
          // console.log(this.option);
        }
      });
    },
  },
};
</script>